import { EDIT_APP_SETTINGS } from "../types";

const initialState = {};
export const appSettingsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case EDIT_APP_SETTINGS: {
      const { data } = payload;
      return { ...state, ...data };
    }
    default: {
      return state;
    }
  }
};
