export const USER = 'USER'
export const USER_BALANCE = 'USER_BALANCE'
export const USER_UPDATE = 'USER_UPDATE'
export const USER_AVATAR_DELETE = 'USER_AVATAR_DELETE'
export const USER_AVATAR_UPLOAD = 'USER_AVATAR_UPLOAD'
export const USER_PROFILE_SET = 'USER_PROFILE_SET'
export const USERS = 'USERS'
export const USER_EMAIL = 'USER_EMAIL'

export const BALANCE = 'BALANCE'
export const BALANCE_UPDATE = 'BALANCE_UPDATE'

export const SET_USER_IN_SHOW = 'SET_USER_IN_SHOW'

export const USERS_IN_CHAT = 'USERS_IN_CHAT'
export const ONE_USER_IN_CHAT = 'ONE_USER_IN_CHAT'
export const REMOVE_USER_IN_CHAT = 'REMOVE_USER_IN_CHAT'

export const IMAGE_VERSION = 'IMAGE_VERSION'

export const BINOM = 'BINOM'
export const BINOM_REMOVE = 'BINOM_REMOVE'

export const MAINTANCE_SET = 'MAINTANCE_SET'
export const MAINTANCE_REMOVE = 'MAINTANCE_REMOVE'

export const ERROR = 'ERROR'
export const ERRORS = 'ERRORS'

export const SOCKET_CONNECTION = 'SOCKET_CONNECTION'
export const REMOVE_SOCKET_CONNECTION = 'REMOVE_SOCKET_CONNECTION'

export const MEMBER_BONUS = 'MEMBER_BONUS'

export const PAUSE_VIDEO = 'PAUSE_VIDEO'
export const START_VIDEO = 'START_VIDEO'

export const ERROR_REMOVE = 'ERROR_REMOVE'
export const ERRORS_REMOVE = 'ERRORS_REMOVE'

export const MESSAGE = 'MESSAGE'
export const MESSAGES = 'MESSAGES'
export const MESSAGE_REMOVE = 'MESSAGE_REMOVE'
export const MESSAGES_REMOVE = 'MESSAGES_REMOVE'

export const NAV_ENABLE = 'NAV_ENABLE'
export const NAV_DISABLE = 'NAV_DISABLE'

export const MODAL_ENABLE = 'MODAL_ENABLE'
export const MODAL_DISABLE = 'MODAL_DISABLE'

export const TAB = 'TAB'
export const TAB_REMOVE = 'TAB_REMOVE'

export const PURCHASE_TYPE = 'PURCHASE_TYPE'
export const PURCHASE_TYPE_REMOVE = 'PURCHASE_TYPE_REMOVE'

export const PRICING = 'PRICING'

export const SET_PRIVATE_SHOW_REQUEST = 'SET_PRIVATE_SHOW_REQUEST'

export const SET_PRIVATE_SHOW_REQUESTS = 'SET_PRIVATE_SHOW_REQUESTS'
export const SET_IS_IN_PRIVATE = 'SET_IS_IN_PRIVATE'
export const REMOVE_PRIVATE_SHOW_REQUEST = 'REMOVE_PRIVATE_SHOW_REQUEST'
export const REMOVE_PRIVATE_SHOW_REQUEST_BY_ID = 'REMOVE_PRIVATE_SHOW_REQUEST_BY_ID'
export const REMOVE_IS_IN_PRIVATE = 'REMOVE_IS_IN_PRIVATE'

export const CONVERSATIONS = 'CONVERSATIONS'
export const CONVERSATIONS_REDUCE_UNREAD = 'CONVERSATIONS_REDUCE_UNREAD'
export const CONVERSATIONS_INCREASE_UNREAD = 'CONVERSATIONS_INCREASE_UNREAD'
export const CONVERSATIONS_REMOVE = 'CONVERSATIONS_REMOVE'
export const CONVERSATION_REMOVE = 'CONVERSATION_REMOVE'
export const CONVERSATION_MOVE_TOP = 'CONVERSATION_MOVE_TOP'

export const ONLINE = 'ONLINE'
export const OFFLINE = 'OFFLINE'

export const POSTS = 'POSTS'
export const POST = 'POST'
export const POST_CLEAR = 'POST_CLEAR'

export const POOLS = 'POOLS'
export const POOL = 'ONE_POOL'
export const INCREMENT_POOL = 'POOL'

export const MYPOOLS = 'MYPOOLS'
export const MYPOOLS_ADD_ONE = 'MYPOOLS_ADD_ONE'
export const MYPOOLS_REMOVE = 'MYPOOLS_REMOVE'

export const MAGIC = 'MAGIC'
export const MAGIC_INCREMENT_VOTE = 'MAGIC_INCREMENT_VOTE'
export const MAGIC_REMOVE = 'MAGIC_REMOVE'

export const MAGIC_TAB = 'MAGIC_TAB'
export const MAGIC_TAB_REMOVE = 'MAGIC_TAB_REMOVE'

export const MODEL = 'MODEL'
export const MODEL_INCREMENT_KISSES = 'MODEL_INCREMENT_KISSES'
export const MODEL_SET_KISSED = 'MODEL_SET_KISSED'
export const MODEL_UNSET_KISSED = 'MODEL_UNSET_KISSED'
export const MODEL_DECREMENT_KISSES = 'MODEL_DECREMENT_KISSES'
export const MODELS = 'MODELS'
export const MODEL_MEDIA = 'MODEL_MEDIA'
export const MODEL_GALLERY = 'MODEL_GALLERY'
export const EDIT_GALLERY = 'EDIT_GALLERY'
export const MODEL_MEDIA_REMOVE = 'MODEL_MEDIA_REMOVE'
export const MODEL_EARNINGS = 'MODEL_EARNINGS'

export const ONLINES = "ONLINES";
export const USERS_ONLINE = "USERS_ONLINE";

export const DECREMENT_CREDITS = 'DECREMENT_CREDITS'
export const INCREMENT_CREDITS = 'INCREMENT_CREDITS'

export const UPDATE = 'UPDATE'

export const CAMERA = 'CAMERA'
export const CAMERAS = 'CAMERAS'
export const REMOVE_CAMERA = 'REMOVE_CAMERA'

export const LIVESHOW = 'LIVESHOW'
export const LIVESHOW_DELETE = 'LIVESHOW_DELETE'
export const LIVESHOWS = 'LIVESHOWS'
export const LIVESHOW_NO_HLS = 'LIVESHOW_NO_HLS'


export const CHAT = "CHAT"
export const ADD_MESSAGE = "ADD_MESSAGE"
export const SET_MESSAGES = "SET_MESSAGES"
export const ADD_MESSAGE_TO_MESSAGES = "ADD_MESSAGE_TO_MESSAGES"
export const REMOVE_MESSAGE = "REMOVE_MESSAGE"

export const PRIVATE_MESSAGES = "PRIVATE_MESSAGES"
export const ADD_MESSAGE_TO_PRIVATE_MESSAGES = "ADD_MESSAGE_TO_PRIVATE_MESSAGES"
export const PRIVATE_MESSAGES_REMOVE = "PRIVATE_MESSAGES_REMOVE"

export const SET_PINNED_MESSAGE = 'SET_PINNED_MESSAGE'
export const REMOVE_PINNED_MESSAGE = 'REMOVE_PINNED_MESSAGE'
export const REMOVE_PINNED_MESSAGES = 'REMOVE_PINNED_MESSAGES'

export const FETCH_PRIVATE_CHATS = "FETCH_PRIVATE_CHATS"
export const FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS"
export const ADD_NOTIFICATIONS = "ADD_NOTIFICATIONS"
export const ADD_NOTIFICATION = "ADD_NOTIFICATION"
export const SET_SEEN_NOTIFICATIONS = "SET_SEEN_NOTIFICATIONS"
export const FETCH_SESSION_REQUESTS = "FETCH_SESSION_REQUESTS"
export const REMOVE_SESSION_REQUEST = "REMOVE_SESSION_REQUEST"


export const HIGHLIGHT = 'HIGHLIGHT'

export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_LOGOUT = "AUTH_LOGOUT";

export const AUTH_ENV_USER = "AUTH_ENV_USER";
export const SET_LOADING = "SET_LOADING";
export const REMOVE_LOADING = "REMOVE_LOADING";

export const SET_LOADINGS = "SET_LOADINGS";
export const REMOVE_LOADINGS = "REMOVE_LOADINGS";

export const INCREMENT_PROGRESS = "INCREMENT_PROGRESS";
export const DECREMENT_PROGRESS = "DECREMENT_PROGRESS";

export const CRYPTOS = 'CRYPTOS'
export const SOCIALS = 'SOCIALS'
export const PACKAGES = 'PACKAGES'
export const EVENTS='EVENTS';
export const MEDIA='MEDIA';
export const ADD_MEDIA='ADD_MEDIA';

export const SET_EDIT='SET_EDIT';

export const SET_WATCHING = "SET_WATCHING";
export const EDIT_WATCHING = "EDIT_WATCHING";

export const EDIT_APP_SETTINGS = "EDIT_APP_SETTINGS";
export const CLEAR_APP_SETTINGS = "CLEAR_APP_SETTINGS";