export const API = "/api/v1/";

export const HOME_ROUTE = "/";
export const BACKEND_URL = "https://whxlb-fr.warehouse-x.io/";
export const LOGIN_ROUTE = "/login";
export const RESET_ROUTE = "/password/reset";
export const REGISTRATION_ROUTE = "/register";
export const PROFILE_ROUTE = "/account";
export const STREAM_ROUTE = "/stream";
export const MODEL_PROFILE_ROUTE = "/models/:id";

export let socket;

export const overlayOptions = {
  delayTime: 2000,
  mobileDelayTime: 4000,
  defaultTab: "housemates",
  tabDelayTime: 3000,
};
//delay time in ms

// core api
export const CORE_API_LOGIN_ROUTE = "/user/auth/login";
export const CORE_API_CHAT_ROUTE = "/chat/";
export const CORE_API_CHAT_TOKEN_ROUTE = API + "environment/chat-token";
export const CORE_API_GET_MESSAGES_ROUTE = "/messages";
export const CORE_API_SEND_MESSAGE_ROUTE = "send-message";
export const CORE_API_SEND_GLOBAL_TIPS_ROUTE = "/tips";
export const CORE_API_ANONYMOUS_WHX_MAIN_USER_LOGIN = "whxMainUser";
export const CORE_API_ANONYMOUS_WHX_MAIN_USER_PASSWORD = "pass";

//api
export const API_REGISTRATION_ROUTE = API + "auth/register";
export const API_LOGIN_ROUTE = API + "auth/login";
export const API_LOGOUT_ROUTE = API + "auth/logout";

export const API_USER_VERIFY_EMAIL_ROUTE = API + "email-verification";
export const API_USER_FINDME_ROUTE = API + "findMe";
export const API_USER_FIND_ANOTHER_USER_ROUTE = API + "findMember/";
export const API_USER_CHANGE_PASSWORD_ROUTE = API + "users/change-password";
export const API_USER_DELETE_AVATAR = API + "user/avatar/delete";
export const API_USER_SET_AVATAR = API + "user/avatar/set";
export const API_USER_AVATAR_UPDATE = API + "users/upload-avatar/";

export const API_SEND_TIPS_ROUTE = API + "member/send-tokens";

export const API_BUY_MODEL_MEDIA_ROUTE = API + "member/purchase-media/";

export const API_USER_SENDS_KISS_ROUTE = API + "likes/";
export const API_USER_SENDS_MESSAGE_ROUTE = API + "model/";
export const API_USER_SENDS_TIPS_ROUTE = API + "member/send-tokens/";
export const API_USER_SENDS_GIFT_ROUTE = API + "findMe";
export const API_FETCH_SESSION_REQUESTS_ROUTE = API + "findMe";
export const API_FETCH_PRIVATE_CHATS_ROUTE = API + "findMe";
export const API_FETCH_POSTS_ROUTE = API + "blogs";
export const API_FETCH_POSTS_BY_TAG_ROUTE = API + "blogs/tag/";
export const API_FETCH_POST_ROUTE = API + "blogs/";
export const API_FETCH_AVATARS = API + "avatars";
export const API_FETCH_USER_CONVERSATIONS = API + "user/my-conversations";
export const API_FETCH_USER_NOTIFICATIONS = API + "user/notifications";
export const API_FETCH_CONVERSATION_WITH = API + "user/get-conversation-with/";

export const API_CREATE_POOL_ROUTE = API + "models/pools";
export const API_EDIT_POOL_ROUTE = API + "models/pools/";

export const API_REDEEM_PROMOCODE = API + "promocode/";
export const API_UPDATE_BINOM = API + "binom/update";

export const API_USER_PROFILE_FETCH_ROUTE = API + "user/profile/";
export const API_USER_PROFILE_UPDATE_ROUTE = API + "user/profile/model";
export const API_USER_MODEL_UPDATE_ROUTE =
  API + "models/dashboard/profile/edit";

export const API_MEMBER_PROFILE_EDIT_ROUTE = API + "user/profile";
export const API_MEMBER_PASSWORD_CHANGE_ROUTE =
  API + "members/account-settings/change-password";
export const API_MEMBER_TIMEZONE_EDIT_ROUTE =
  API + "members/account-settings/other-settings";

export const API_MODEL_PROFILE_EDIT_ROUTE =
  API + "models/dashboard/profile/edit";
export const API_MODEL_TIMEZONE_EDIT_ROUTE = API + "models/other-settings";
export const API_MODEL_GEOBLOCK_EDIT_ROUTE =
  API + "models/dashboard/geo-blocking";
export const API_MODEL_EARNINGS_ROUTE = API + "models/dashboard/earnings";

export const API_FETCH_MODELS_ROUTE = API + "top-models";
export const API_FETCH_MODELS_ROUTE_ACTIVE = API + "top-models/active";
export const API_FETCH_ONLINE_MODELS_ROUTE = API + "online";
export const API_FETCH_USERS_IN_CHAT_ROUTE = API + "users/online";
export const API_FETCH_MODEL_ROUTE = API + "get-model/";
export const API_FETCH_MODEL_MEDIA_ROUTE = API + "models/";
export const API_MODEL_MEDIA_DELETE_ROUTE = API + "models/media/file/delete/";
export const API_MODEL_VIDEO_DELETE_ROUTE = API + "models/media/video/delete/";
export const API_MODEL_PRICELIST_ROUTE = API + "models/pricelist";

export const API_FETCH_CAMERAS_ROUTE = API + "rooms";
export const API_FETCH_CAMERA_ROUTE = API + "room/";

export const API_FETCH_LIVESHOWS_ROUTE = API + "liveshows";
export const API_FETCH_LIVESHOW_ROUTE = API + "liveshow/";
export const API_FETCH_PRIVATE_LIVESHOW_ROUTE = API + "private-liveshow/";
export const API_END_PRIVATE_SHOW_ROUTE = API + "liveshow/";
export const API_REQUEST_PRIVATE_SHOW_ROUTE = API + "liveshow/";
export const API_CANCEL_REQUEST_PRIVATE_SHOW_ROUTE =
  API + "liveshow/private-request/";
export const API_DECIDE_PRIVATE_SHOW_ROUTE = API + "liveshow/model/requests/";

export const API_FETCH_POOLS = API + "all-pools";
export const API_FETCH_MY_MODEL_POOLS = API + "all-pools";
export const API_FETCH_LIVESHOW_POOLS = API + "liveshow/";
export const API_FETCH_ENVIRONMENT_ROUTE = API + "environment";
export const API_FETCH_CRYPTOS_ROUTE = API + "cryptos";
export const API_FETCH_SOCIALS_ROUTE = API + "socials";

export const API_FETCH_PAYMENT_PACKAGES_ROUTE = API + "user/buy-credits";
export const API_FETCH_USER_REQUESTS_ROUTE = API + "liveshow/requests";

export const API_USER_LEFT_LIVESHOW = API + "liveshows/id/user/id/left";

export const API_EVENTS_ROUTE = API + "events";
export const API_MEDIA_BANK_ROUTE = API + "media-bank";
export const API_GET_VIDEO_ROUTE = API + "models/media/video/";

export const API_GET_CAMERA_ACTIVITY_ROUTE = API + "camera-activity/";

export const API_RESTORE_LINK_ROUTE = API + 'forgot-password-link';
export const API_RESTORE_ROUTE = API + 'forgot-password';
export const API_MAGIC_VOTE = API + 'magicx/vote/';
export const API_GET_MAGIC = API + 'magicx/environment';
export const API_GET_SHIPPING_COST = API + 'magicx/calculate-shipping';
export const API_MAGIC_PURCHASE = API + 'magicx/purchase';

