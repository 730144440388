import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logoutUser } from '../../redux/actions/userAction';

const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => dispatch(logoutUser())
  }
}

function Logout({ logoutUser }) {
  const navigate = useNavigate();
  useEffect(() => {
    logoutUser();
    navigate('/');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return;
}

export default connect(null, mapDispatchToProps)(Logout);
