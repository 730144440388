// api-client.js

import axios from "axios";
import { setBalance } from "../redux/actions/environmentAction";
import { setMessage } from "../redux/reducers/rootReducer";
import { store } from "../redux/store";

// Request middleware
axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    if (localStorage.getItem("access_token") === null) {
      let root = localStorage.getItem("persist:root");
      root = JSON.parse(root);
      if (!root) return config;
      let session = JSON.parse(root.session);
      if (!session) return config;
      const access_token = session.access_token;
      if (!access_token) return config;
      localStorage.setItem("access_token", access_token);
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Response middleware
axios.interceptors.response.use(
  function (response) {
    // Do something with response data

    if (
      response.data.message &&
      response.data.message !== null &&
      response.data.message !== ""
    )
      store.dispatch(setMessage(response.data.message));

    //redirect
    if (response.data && response.data.data && response.data.data.redirectUrl)
      if (window.location.pathname !== response.data.data.redirectUrl)
        window.location.href = response.data.data.redirectUrl;
    return response;
  },
  function (error) {
    /*  console.error(error); */
    // Do something with response error
    if (error.response.status === 401) {
      localStorage.removeItem("persist:root");
      window.location.href = "/";
    } else if (error.response.status === 404) {
    } else if (error.response.status === 403) {
      localStorage.removeItem("persist:root");
      localStorage.removeItem("core_api_token");
      localStorage.removeItem("access_token");
      window.localStorage.clear();
      window.localStorage.clear();
      localStorage.clear();
      localStorage.clear();
      window.sessionStorage.clear();
      window.localStorage.clear();
      store.dispatch(setBalance({}));
      localStorage.setItem("user_age_check", true);
      window.location.href = "/";
    } else if (error.response.status === 500) {
    } else {
      error.response.data.message &&
      error.response.data.message !== null &&
      error.response.data.message !== ""
        ? error.response.data.data?.tokens
          ? store.dispatch(
              setMessage(error.response.data.message, "error", null, true)
            )
          : error.response.data.message !== {} &&
            typeof error.response.data.message !== "object" &&
            store.dispatch(setMessage(error.response.data.message, "error"))
        : error.response.data.errors
        ? error.response.data.errors[0]
          ? store.dispatch(setMessage(error.response.data.errors[0], "error"))
          : store.dispatch(
              setMessage(Object.values(error.response.data.errors)[0], "error")
            )
        : error.response.data.errors[0]
        ? store.dispatch(setMessage(error.response.data.errors[0], "error"))
        : error.response.message !== {} &&
          store.dispatch(setMessage(error.response.message, "error"));
    }
    return Promise.reject(error);
  }
);

export default axios;
