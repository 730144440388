import * as React from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import model from "../../assets/images/lil.webp";
import model2 from "../../assets/images/lsbg.jpg";
import LoadingButton from "@mui/lab/LoadingButton";
import { removeBinom, userSignin } from "../../redux/actions/userAction";
import { useTheme } from "@mui/material";
import {
  removeErrors,
  setErrors,
  removeError,
  setError,
  hideModal,
  showModal,
} from "../../redux/reducers/rootReducer";
import { useTranslation } from "react-i18next";
import { Turnstile } from "@marsidev/react-turnstile";
import { API_LOGIN_ROUTE, API_REGISTRATION_ROUTE } from "../../utils/consts";
import { styled } from "@mui/system";
import { withTheme } from "@mui/styles";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import EmailIcon from "@mui/icons-material/Email";
import { isMobile } from "react-device-detect";
import CloseButton from "../../UI/CloseButton";
import { getEnvironment } from "../../redux/actions/environmentAction";
const supportsVR = "getVRDisplays" in navigator;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 800,
  minWidth: isMobile ? 360 : 320,
  minHeight: 410,
  width: "100%",
  bgcolor: "#171717",
  textAlign: "center",
  margin: "0 auto",
  borderRadius: "0px 10px 10px 0px",
  boxShadow: "0px 3px 20px #000000",
  padding: "unset !important",
};

const StyledTextField = styled(withTheme(TextField))((props) => ({
  backgroundColor: "white",
  borderRadius: "10px",
  "& input": {
    borderRadius: "10px",
    paddingLeft: "50px",
    color: "black",
    fontSize: "0.9rem",
  },
  "& fieldset": {
    borderRadius: "10px",
  },
  "& .MuiFormLabel-root": {
    paddingLeft: "30px",
    fontSize: "0.9rem",
  },
  "& .MuiFormLabel-asterisk": {
    display: "none",
  },
}));

export default function RegistrationModal({ isRegister, isLogin }) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [logReg, setLogRes] = React.useState(false);
  const [captcha, setCaptcha] = React.useState(null);
  const housemates = useSelector((state) => state.onlines);
  const [turnstille, setTurnstille] = React.useState(true);
  const location = useLocation();
  const turnstileRef = React.useRef();
  const error = useSelector((state) => state.error);
  const errors = useSelector((state) => state.errors);
  const modal = useSelector((state) => state.modal);
  const binom = useSelector((state) => state.binom);
  const [pending, setPending] = React.useState(false);
  const dispatch = useDispatch();

  const loginAvatar = React.useMemo(() => {
    return model2;
  }, []);

  const registerAvatar = React.useMemo(() => {
    return model2;
  }, []);
  // let [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation();

  React.useEffect(() => {
    if (location.pathname === "/login" || isLogin) {
      handleOpen(true);
      setLogRes(!logReg);
    } else if (location.pathname === "/register" || isRegister) {
      handleOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleOpen = (page) => {
    // setSearchParams({login: true});
    if (page === "login") {
      setLogRes(true);
    } else if (page === "register") {
      setLogRes(false);
    }
    setOpen(true);
  };

  const handleClose = () => {
    // setSearchParams({login: false});
    dispatch(isLogin ? hideModal("login") : hideModal("register"));
    setOpen(false);
    clear();
  };

  React.useEffect(() => {
    if (modal.name === "signup") {
      handleOpen(true);
    }
  }, [modal]);

  const [newUser, setNewUser] = React.useState({
    username: null,
    email: null,
    password: null,
    password_confirmation: null,
  });

  const [userLogin, setUserLogin] = React.useState({
    username: null,
    password: null,
  });

  function setData(event) {
    if (event.target.name === "username") {
      setNewUser({ ...newUser, username: event.target.value });
    }
    if (event.target.name === "email") {
      setNewUser({ ...newUser, email: event.target.value });
    }
    if (event.target.name === "password") {
      setNewUser({ ...newUser, password: event.target.value });
    }
    if (event.target.name === "password_confirm") {
      setNewUser({ ...newUser, password_confirmation: event.target.value });
    }
    if (event.target.name === "emailUser") {
      setUserLogin({ ...userLogin, username: event.target.value });
    }
    if (event.target.name === "passwordUser") {
      setUserLogin({ ...userLogin, password: event.target.value });
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();
    if (process.env.NODE_ENV === "production" && !supportsVR) {
      if (!captcha) {
        dispatch(setErrors({ captcha: t("Are you a robot?") }));
        return;
      }
    }
    if (newUser.username === null) {
      dispatch(setErrors({ username: t("The username is required.") }));
    } else if (newUser.email === null) {
      dispatch(setErrors({ email: t("The email is required.") }));
    } else if (newUser.password === null) {
      dispatch(setErrors({ password: t("The password is required.") }));
    } else if (newUser.password_confirmation === null) {
      dispatch(
        setErrors({
          password_confirmation: t("The password confirmation is required."),
        })
      );
    } else if (newUser.password !== newUser.password_confirmation) {
      dispatch(
        setErrors({
          password_confirmation: t("The passwords are not the same."),
        })
      );
    } else {
      dispatch(removeError());
      dispatch(removeErrors());
      let item;
      if (process.env.NODE_ENV === "production") {
        item = {
          username: newUser.username,
          email: newUser.email,
          password: newUser.password,
          password_confirmation: newUser.password_confirmation,
          "cf-turnstile-response": captcha,
        };
      } else {
        item = {
          username: newUser.username,
          email: newUser.email,
          password: newUser.password,
          password_confirmation: newUser.password_confirmation,
        };
      }

      setPending(true);
      try {
        if (
          binom &&
          binom !== {} &&
          Object.getOwnPropertyNames(binom).length !== 0
        ) {
          item["binom"] = binom;
        }

        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(item),
        };
        const response = await fetch(
          process.env.REACT_APP_BACKEND_URL + API_REGISTRATION_ROUTE,
          options
        );
        const data = await response.json();

        if (data.access_token) {
          setPending(false);
          localStorage.setItem("core_api_token", data.kewb_token);
          localStorage.setItem("access_token", data.access_token);
          dispatch(userSignin(data));
          handleClose();
          if (data?.user?.role === "member") {
            setTimeout(() => {
              window.location.reload();
            }, 300);
          } else {
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        } else {
          setPending(false);
          process.env.NODE_ENV === "production" && resetCaptcha();
          dispatch(setErrors(data.errors));
        }
      } catch (error) {
        setPending(false);
        process.env.NODE_ENV === "production" && resetCaptcha();
      }
    }
  }

  function clear() {
    setCaptcha(null);
    setNewUser({
      username: null,
      email: null,
      password: null,
      password_confirmation: null,
    });
    setUserLogin({
      username: null,
      password: null,
    });
  
    // Wait for the state to update, then clear the input fields
    setTimeout(() => {
      const inputs = document.querySelectorAll("input");
      inputs.forEach((input) => {
        input.value = "";
      });
    }, 1);

    dispatch(removeErrors());
    dispatch(removeError());
  }

  function loginFormChange(event) {
    event.preventDefault();
    clear();
    setLogRes(!logReg);
  }

  function onChange(value) {
    console.log("Captcha successfuly completed!");
    dispatch(removeError());
    setCaptcha(value);
  }

  function resetCaptcha() {
    turnstileRef.current?.reset();
    setTurnstille(false);
    setTimeout(() => {
      setTurnstille(true);
    }, 50);
  }

  async function loginForm(event) {
    event.preventDefault();
    if (process.env.NODE_ENV === "production" && !supportsVR) {
      if (!captcha) {
        dispatch(setErrors({ captcha: "Are you a robot?" }));
        return;
      }
    }

    dispatch(removeError());
    dispatch(removeErrors());
    let data;
    if (process.env.NODE_ENV === "production" && !supportsVR) {
      data = {
        username: userLogin.username,
        password: userLogin.password,
        "cf-turnstile-response": captcha,
      };
    } else {
      data = {
        username: userLogin.username,
        password: userLogin.password,
      };
    }

    setPending(true);
    try {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(data),
      };

      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL + API_LOGIN_ROUTE,
        options
      );
      const dataResp = await response.json();

      if (dataResp.access_token) {
        setPending(false);
        localStorage.setItem("access_token", dataResp.access_token);
        localStorage.setItem("core_api_token", dataResp.kewb_token);
        dispatch(userSignin(dataResp));
        console.log(dataResp);
        handleClose();
        if (dataResp?.user?.role === "member") {
          setTimeout(() => {
            window.location.reload();
          }, 300);
        } else {
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
        // dispatch(getEnvironment('landing'))
      } else {
        setPending(false);
        process.env.NODE_ENV === "production" && !supportsVR && resetCaptcha();
        dispatch(setError(dataResp.message));
      }
    } catch (error) {
      setPending(false);
      process.env.NODE_ENV === "production" && !supportsVR && resetCaptcha();
    }
  }
  const inputRef = React.useRef(null);

  // React.useEffect(() => {
  //   setTimeout(() => {
  //     inputRef?.current?.click();
  //   }, 1000);
  // }, [open]);
  React.useEffect(() => {
    if (open && inputRef.current) {
      inputRef?.current?.focus();
    }
  }, [open]);

  const handleForgotPassword = () => {
    handleClose();
    dispatch(showModal("restore"));
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className="pop-up"
      onEntered={() => inputRef.current.focus()}
    >
      <Fade in={open}>
        <Box
          sx={style}
          style={{
            width: window.innerWidth < 1050 ? "unset" : "100%",
            padding: window.innerWidth < 1050 ? "24px" : "32px",
            borderRadius: "5px",
          }}
        >
          <CloseButton onClick={handleClose} />
          <Grid
            container
            className="flex"
            sx={{ flexDirection: isMobile ? "column-reverse" : "" }}
          >
            <CssBaseline />
            <Grid
              item
              xs={12}
              sm={12}
              lg={6}
              sx={{
                marginTop: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                p: "42px 47px 50px 47px",
              }}
              style={{ boxShadow: "none" }}
            >
              <Typography
                component="h1"
                variant="h2"
                className="font EffraBold"
                fontSize={window.innerWidth < 1050 ? "1rem" : "1.5rem"}
                sx={{ alignSelf: "flex-start" }}
              >
                {!logReg ? t("Create your free account") : t("Login")}
              </Typography>
              <Box component="form" noValidate sx={{ mt: 4 }}>
                <Grid container spacing={2} onChange={setData}>
                  {!logReg ? (
                    <>
                      <Grid sx={{ position: "relative" }} item xs={12} sm={12}>
                        <Box
                          className="flex items-center"
                          sx={{
                            position: "absolute",
                            zIndex: 1,
                            top: "12px",
                            bottom: "0",
                            left: "25px",
                          }}
                        >
                          <PersonIcon
                            color="black"
                            sx={{ fontSize: "1.7rem" }}
                          />
                        </Box>
                        <StyledTextField
                          autoComplete="given-name"
                          name="username"
                          required
                          fullWidth
                          id="username"
                          label={t("Username")}
                          autoFocus
                          ref={inputRef}
                        />
                      </Grid>
                      {errors?.username && (
                        <Grid
                          item
                          xs={12}
                          sx={{
                            p: "8px 0 0 16px !important",
                            textAlign: "start",
                          }}
                        >
                          <Typography
                            fontSize="0.8rem"
                            sx={{
                              color: theme.palette.error.main,
                            }}
                          >
                            {errors.username}
                          </Typography>
                        </Grid>
                      )}
                      <Grid sx={{ position: "relative" }} item xs={12} sm={12}>
                        <Box
                          className="flex items-center"
                          sx={{
                            position: "absolute",
                            zIndex: 1,
                            top: "12px",
                            bottom: "0",
                            left: "25px",
                          }}
                        >
                          <EmailIcon
                            color="black"
                            sx={{ fontSize: "1.5rem" }}
                          />
                        </Box>
                        <StyledTextField
                          required
                          fullWidth
                          id="email"
                          label={t("Email")}
                          name="email"
                          autoComplete="email"
                        />
                      </Grid>
                      {errors?.email && (
                        <Grid
                          item
                          xs={12}
                          sx={{
                            p: "8px 0 0 16px !important",
                            textAlign: "start",
                          }}
                        >
                          <Typography
                            fontSize="0.8rem"
                            sx={{
                              color: theme.palette.error.main,
                            }}
                          >
                            {errors.email}
                          </Typography>
                        </Grid>
                      )}
                      <Grid sx={{ position: "relative" }} item xs={12}>
                        <Box
                          className="flex items-center"
                          sx={{
                            position: "absolute",
                            zIndex: 1,
                            top: "12px",
                            bottom: "0",
                            left: "25px",
                          }}
                        >
                          <LockIcon color="black" sx={{ fontSize: "1.5rem" }} />
                        </Box>
                        <StyledTextField
                          required
                          fullWidth
                          id="password"
                          label={t("Password")}
                          name="password"
                          type="password"
                          autoComplete="new-password"
                        />
                      </Grid>
                      {errors?.password && (
                        <Grid
                          item
                          xs={12}
                          sx={{
                            p: "8px 0 0 16px !important",
                            textAlign: "start",
                          }}
                        >
                          <Typography
                            fontSize="0.8rem"
                            sx={{
                              color: theme.palette.error.main,
                            }}
                          >
                            {errors.password}
                          </Typography>
                        </Grid>
                      )}
                      <Grid sx={{ position: "relative" }} item xs={12}>
                        <Box
                          className="flex items-center"
                          sx={{
                            position: "absolute",
                            zIndex: 1,
                            top: "12px",
                            bottom: "0",
                            left: "25px",
                          }}
                        >
                          <LockIcon color="black" sx={{ fontSize: "1.5rem" }} />
                        </Box>
                        <StyledTextField
                          required
                          fullWidth
                          name="password_confirm"
                          label={t("Repeat password")}
                          type="password"
                          id="password_confirm"
                          // type="password"
                          autoComplete="confirm-password"
                        />
                      </Grid>
                      {errors?.password_confirmation && (
                        <Grid
                          item
                          xs={12}
                          sx={{
                            p: "8px 0 0 16px !important",
                            textAlign: "start",
                          }}
                        >
                          <Typography
                            fontSize="0.8rem"
                            sx={{
                              color: theme.palette.error.main,
                            }}
                          >
                            {errors.password_confirmation}
                          </Typography>
                        </Grid>
                      )}

                      <Grid item xs={12}>
                        {process.env.NODE_ENV === "production" &&
                          !supportsVR &&
                          turnstille && (
                            <Turnstile
                              siteKey="0x4AAAAAAABRt4hMYhLY1p8e"
                              ref={turnstileRef}
                              options={{
                                theme: "dark",
                              }}
                              onSuccess={(token) => onChange(token)}
                            />
                          )}
                      </Grid>
                      {process.env.NODE_ENV === "production" &&
                        !supportsVR &&
                        errors?.captcha && (
                          <Grid
                            item
                            xs={12}
                            sx={{
                              p: "8px 0 0 16px !important",
                              textAlign: "start",
                            }}
                          >
                            <Typography
                              fontSize="0.8rem"
                              sx={{
                                color: theme.palette.error.main,
                              }}
                            >
                              {errors.captcha}
                            </Typography>
                          </Grid>
                        )}
                    </>
                  ) : (
                    <>
                      <Grid sx={{ position: "relative" }} item xs={12} sm={12}>
                        <Box
                          className="flex items-center"
                          sx={{
                            position: "absolute",
                            zIndex: 1,
                            top: "12px",
                            bottom: "0",
                            left: "25px",
                          }}
                        >
                          <PersonIcon
                            color="black"
                            sx={{ fontSize: "1.7rem" }}
                          />
                        </Box>
                        <StyledTextField
                          required
                          ref={inputRef}
                          autoFocus
                          fullWidth
                          id="email"
                          label={t("Username")}
                          name="emailUser"
                          autoComplete="email"
                        />
                      </Grid>
                      <Grid sx={{ position: "relative" }} item xs={12}>
                        <Box
                          className="flex items-center"
                          sx={{
                            position: "absolute",
                            zIndex: 1,
                            top: "12px",
                            bottom: "0",
                            left: "25px",
                          }}
                        >
                          <LockIcon color="black" sx={{ fontSize: "1.5rem" }} />
                        </Box>
                        <StyledTextField
                          required
                          fullWidth
                          id="password"
                          label={t("Password")}
                          name="passwordUser"
                          type="password"
                          autoComplete="new-password"
                        />
                      </Grid>
                      {error && typeof error === "string" && (
                        <Grid
                          item
                          xs={12}
                          sx={{
                            p: "8px 0 0 16px !important",
                            textAlign: "start",
                          }}
                        >
                          <Typography
                            fontSize="0.8rem"
                            sx={{
                              color: theme.palette.error.main,
                            }}
                          >
                            {error}
                          </Typography>
                        </Grid>
                      )}
                      {process.env.NODE_ENV === "production" &&
                        !supportsVR &&
                        turnstille && (
                          <Grid item xs={12}>
                            <Turnstile
                              siteKey="0x4AAAAAAABRt4hMYhLY1p8e"
                              ref={turnstileRef}
                              options={{
                                theme: "dark",
                              }}
                              onSuccess={(token) => onChange(token)}
                              autoResetOnExpire
                              onError={() => resetCaptcha()}
                            />
                          </Grid>
                        )}
                      {process.env.NODE_ENV === "production" &&
                        !supportsVR &&
                        errors?.captcha && (
                          <Grid
                            item
                            xs={12}
                            sx={{
                              p: "8px 0 0 16px !important",
                              textAlign: "start",
                            }}
                          >
                            <Typography
                              fontSize="0.8rem"
                              sx={{
                                color: theme.palette.error.main,
                              }}
                            >
                              {errors.captcha}
                            </Typography>
                          </Grid>
                        )}
                    </>
                  )}
                </Grid>

                {/* {logReg &&
                  <Box sx={{ width: 'fit-content', ml: 'auto', mt: 0.5 }}>
                    <Link
                      component="button"
                      variant="body2"
                      onClick={loginFormChange}
                      sx={{
                        textDecoration: "underline",
                        color: theme.palette.third.main,
                        fontSize: '0.9rem',
                        lineHeight: 'inherit'
                      }}
                    >
                      {t("Forgot password?")}
                    </Link>
                  </Box>
                } */}
                <Box sx={{ width: "fit-content", ml: "auto" }}>
                  <Link
                    variant="body2"
                    onClick={handleForgotPassword}
                    sx={{
                      textDecoration: "underline !important",
                      color: theme.palette.white.main,
                      fontSize: "0.9rem",
                      lineHeight: "inherit",
                    }}
                  >
                    {t("Forgot password?")}
                  </Link>
                </Box>

                <LoadingButton
                  type="submit"
                  fullWidth
                  loading={pending}
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 3,
                    py: 1.5,
                    borderRadius: "10px",
                    textTransform: "unset",
                    fontSize: "1.1rem",
                    ".MuiCircularProgress-root": {
                      width: "23px !important",
                      height: "23px !important",
                    },
                  }}
                  color="primary"
                  onClick={logReg ? loginForm : handleSubmit}
                >
                  {logReg ? t("Login") : t("Signup")}
                </LoadingButton>

                <Box>
                  <Typography
                    className={"flex center" + (isMobile ? " column" : "")}
                    fontSize={window.innerWidth < 1050 ? "0.9rem" : "1rem"}
                  >
                    <span>
                      {logReg
                        ? t("Don't have an account?")
                        : t("Already have an account?")}
                    </span>

                    <Link
                      component="button"
                      variant="body2"
                      onClick={loginFormChange}
                      sx={{
                        ml: "4px",
                        textDecoration: "underline",
                        color: theme.palette.third.main,
                        fontSize: "inherit",
                        lineHeight: "inherit",
                      }}
                    >
                      {logReg ? t("Signup now!") : t("Login now!")}
                    </Link>
                  </Typography>
                </Box>
              </Box>
            </Grid>
            {!isMobile && (
              <Grid
                className="flex column items-center"
                item
                xs={12}
                sm={6}
                sx={{
                  backgroundImage: logReg
                    ? "url(" + loginAvatar + ")"
                    : "url(" + registerAvatar + ")",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  justifyContent: "flex-end",
                  py: 6,
                  minHeight: isMobile ? "20vh" : "",
                }}
              >
                <Typography
                  variant="h1"
                  fontSize={"1.5rem"}
                  sx={{
                    textAlign: "start",
                    textShadow: "0px 3px 6px #00000070",
                  }}
                >
                  {t("Welcome to the")}
                  <br />
                  {t("Entertainment Revolution!")}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Box>
      </Fade>
    </Modal>
  );
}
