import {
  Box,
  Button,
  Menu,
  MenuItem,
  Typography,
  IconButton,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  TextareaAutosize,
  Grow,
  Alert,
  DialogActions,
} from "@mui/material";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { getConversationWith } from "../../redux/actions/messagePrivateAction";
import { useTranslation } from "react-i18next";
import StringHelper from "../../utils/StringHelper";
import CampaignRoundedIcon from "@mui/icons-material/CampaignRounded";
import styles from "./menu.module.css";
import PrivateMessageIcon from "../../assets/images/icons/png/comment.png";
import MediaIcon from "../../assets/images/icons/png/media.png";
import ProfileIcon from "../../assets/images/icons/png/face.png";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import LushIcon from "../../assets/images/icons/png/lush.png";
import CircleIcon from "@mui/icons-material/Circle";
import { setErrors, setMessage } from "../../redux/reducers/rootReducer";
import { API_SEND_TIPS_ROUTE } from "../../utils/consts";
import axios from "axios";
import { setBalance } from "../../redux/actions/environmentAction";
import { Avatar } from "antd";

function UserMenu({
  open,
  setOpen,
  anchorEl,
  model,
  liveshow,
  findModel,
  chat,
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const session = useSelector((state) => state.session);
  const dispatch = useDispatch();
  const theme = useTheme();
  const models = useSelector((state) => state.onlines);
  const balance = useSelector((state) => state.balance);
  const [fModel, setFModel] = React.useState(null);
  const privateMessagePrice = localStorage.getItem("privateMessagePrice");

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (findModel) {
      setFModel(models.filter((fModel) => fModel.id === model.id)[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [findModel]);

  const id =
    "faked-reference-popper-live-" + Math.floor(Math.random(100, 20000) * 1000);

  const [tipsAmount, setTipsAmount] = React.useState(10);
  const [openD, setOpenD] = React.useState(false);
  const [appearD, setAppearD] = React.useState(false);
  const [error, setError] = React.useState("");

  function handleTip(e) {
    e.preventDefault();
    setOpen(false);
    if (!session || !session.user) {
      navigate("/register");
      return;
    }
    handleClickOpen();
  }

  function handleMessage(e) {
    e.preventDefault();
    setOpen(false);
    if (!session || !session.user) {
      navigate("/register");
      return;
    }
    dispatch(getConversationWith(model.id));
  }

  function handleViewProfile(e) {
    e.preventDefault();
    setOpen(false);
    navigate(
      "/housemate/" +
        StringHelper.replaceSpacesWithDashes(model.inHouseName).toLowerCase()
    );
  }
  function handleViewProfileMedia(e) {
    e.preventDefault();
    setOpen(false);
    navigate(
      "/housemate/" +
        StringHelper.replaceSpacesWithDashes(model.inHouseName).toLowerCase()
    );
    setTimeout(() => {
      document.getElementById("modelMedia").scrollIntoView({
        block: "start",
        inline: "nearest",
        behavior: "smooth",
        alignToTop: true,
      });
    }, 500);
  }

  const MenuItems = React.useMemo(() => {
    return [
      {
        icon: null,
        componentIcon: <AttachMoneyOutlinedIcon />,
        title: t("Send tips"),
        subtitle: null,
        action: handleTip,
      },
      {
        icon: PrivateMessageIcon,
        title: t("Send Private Message"),
        subtitle: privateMessagePrice + " " + t("credits per message"),
        action: handleMessage,
      },
      {
        icon: MediaIcon,
        title: t("See Media"),
        subtitle: t("Her videos and photos"),
        action: handleViewProfileMedia,
      },
      {
        icon: ProfileIcon,
        title: t("See Complete Profile"),
        subtitle: null,
        action: handleViewProfile,
      },
    ];
  }, []);

  const handleClickOpen = () => {
    setOpenD(true);
    setTipsAmount(10);
  };

  const handleCloseD = () => {
    setOpenD(false);
  };
  const scrollToBottomOnce = React.useCallback(() => {
    const chatElem = document.getElementById("ChatList2");
    if (!chatElem) return;
    chatElem.scrollTop = chatElem.scrollHeight;
  }, []);
  function handleSendTips(event) {
    event.preventDefault();
    if (tipsAmount <= 0) {
      setAppearD(true);
      setError(t("Please provide a valid amount"));
      return;
    } else if (tipsAmount > balance.credits.total) {
      setAppearD(true);
      setError(t("Not enough funds"));
      return;
    } else if (isNaN(tipsAmount)) {
      setAppearD(true);
      setError(t("Please provide a valid amount"));
      return;
    } else if (tipsAmount % 1 !== 0) {
      setAppearD(true);
      setError(t("Please provide a whole amount"));
      return;
    }

    dispatch(sendTips(tipsAmount, "model", model ?? fModel));
    setOpenD(false);
    scrollToBottomOnce()
  }

  function handleChangeTips(event) {
    event.preventDefault();
    setAppearD(false);
    setTipsAmount(
      event.target.value < 0 ? (event.target.value = 0) : event.target.value
    );
  }

  function handleSetMaxTips(event) {
    event.preventDefault();
    setTipsAmount(session.user.credits);
  }

  const sendTips = async (amount, type, model, chatId = null) => {
    let formData;
    if (liveshow && liveshow.chat) {
      formData = {
        tokens: amount,
        type: type,
        chatId: +liveshow.chat.id,
      };
    } else {
      formData = {
        tokens: amount,
        type: type,
        chatId: chatId,
      };
    }

    const res = await axios.post(
      process.env.REACT_APP_BACKEND_URL +
        API_SEND_TIPS_ROUTE +
        (model.id ? "/" + model.id : ""),
      formData,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ` + localStorage.getItem("access_token"),
        },
      }
    );
    if (res.status === 201) {
      setOpen(false);
      setOpenD(false);
      dispatch(setMessage(res.data.message));
      dispatch(setBalance(res.data.balance));
      scrollToBottomOnce()
    } else {
      res.data.message
        ? dispatch(setMessage(res.data.message, "error", null, true))
        : res.data.errors && dispatch(setErrors(res.data.errors));
    }
  };

  const handleActivateLush = (tipsAmount, model) => {
    sendTips(tipsAmount, "lush", model);
    scrollToBottomOnce()
  };

  React.useEffect(() => {
    setOpen(open);
  }, [open, setOpen]);

  return (
    <>
      <Menu
        id={id}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="MenuTooltip"
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        transformOrigin={{ vertical: "bottom", horizontal: "center" }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{
          ".MuiList-root": {
            padding: 0,
          },
        }}
      >
        <Box className="flex column" sx={{ position: "relative" }}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            style={{
              width: "fit-content",
              marginLeft: "15px",
              marginTop: "5px",
              position: "absolute",
              right: "10px",
              top: "2px",
              zIndex: 5,
              color: "white",
            }}
          >
            <CloseIcon />
          </IconButton>
          {chat != true && (
            <Box sx={{ width: 320, height: 210 }}>
              <img
                src={model.avatar?.imageLarge || fModel?.avatar?.imageLarge}
                alt={model.inHouseName}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </Box>
          )}

          <Box
            className="flex column"
            sx={{
              gap: 2,
              padding: "20px 16px 30px",
              backgroundColor: "#000",
              maxWidth: 320,
            }}
          >
            <Box className="flex column" sx={{ gap: 1 }}>
              <Box className="flex items-center" sx={{ gap: 1 }}>
                <Typography fontSize="1.2rem" className="font EffraBold">
                  {model.inHouseName}
                </Typography>
                <Typography
                  fontSize="0.9rem"
                  className="font EffraRegular"
                  sx={{
                    color: theme.palette.primary.main,
                    textTransform: "capitalize",
                  }}
                >
                  {model.accountStatus === "active"
                    ? "in house"
                    : model.accountStatus}
                </Typography>
              </Box>
              {model.status && (
                <Box
                  color="third"
                  className="flex items-center"
                  sx={{ gap: 1 }}
                >
                  <CampaignRoundedIcon color="secondary" />
                  <Typography
                    fontSize="0.9rem"
                    sx={{ color: theme.palette.secondary.main }}
                  >
                    {model.status}
                  </Typography>
                </Box>
              )}
            </Box>

            <Box className="flex column" sx={{ gap: 1 }}>
              {MenuItems.map((item, i) => {
                return (
                  <MenuItem
                    onClick={item.action}
                    key={"user-men-" + i}
                    className="flex"
                    sx={{
                      padding: "8px 18px !important",
                      gap: 2,
                      minHeight: "54px !important",
                      backgroundColor: "#191919",
                      borderRadius: "10px",
                      "&:hover": {
                        backgroundColor: "#212121",
                      },
                    }}
                  >
                    {item.icon && <img src={item.icon} alt={item.title} />}
                    {item.componentIcon && item.componentIcon}
                    <Box className="flex column">
                      <Typography fontSize={14}>{item.title}</Typography>
                      <Typography
                        lineHeight={1}
                        className="font EffraRegular"
                        fontSize={13}
                        sx={{ color: theme.palette.third.main }}
                      >
                        {item.subtitle}
                      </Typography>
                    </Box>
                  </MenuItem>
                );
              })}
            </Box>
          </Box>

          {model.lush_status && (
            <Box
              className="flex column items-center"
              sx={{
                padding: "26px 20px 16px",
                backgroundColor: "#ED2779",
                gap: 1.5,
                position: "relative",
              }}
            >
              <Box
                className="flex center"
                sx={{
                  position: "absolute",
                  top: -17,
                  background: "white",
                  borderRadius: "50%",
                  border: "3px solid #ED2779B4",
                  width: 34,
                  height: 34,
                }}
              >
                <img
                  src={LushIcon}
                  style={{ width: 20 }}
                  alt={t("Active lush")}
                />
              </Box>
              <Typography className="font EffraBold" fontSize="0.9rem">
                {t("Activate")} {model.inHouseName}’s Lush
              </Typography>
              <Typography
                sx={{ alignSelf: "flex-start" }}
                className="font EffraRegular"
                fontSize="0.9rem"
              >
                {t("Intensity, Duration & Price (Credits)")}
              </Typography>
              <Box className="flex items-center" sx={{ gap: "4px" }}>
                {model?.lovense_pricing?.length > 0 &&
                  model.lovense_pricing.map((pricing, i) => (
                    <Box
                      sx={{ gap: 1 }}
                      className="flex column items-center"
                      key={"pricing-l" + i}
                    >
                      <Box className="flex items-center" sx={{ gap: "2px" }}>
                        {Array.from({ length: pricing.intensity }, (i) => (
                          <CircleIcon
                            key={"lshc" + i}
                            sx={{ width: "7px", height: "7px" }}
                          />
                        ))}
                      </Box>
                      <Button
                        className="flex column"
                        color="white"
                        onClick={() => handleActivateLush(pricing.from, model)}
                        sx={{
                          backgroundColor: "#D01D67",
                          justifyContent: "space-between",
                          textTransform: "unset !important",
                          gap: 1.5,
                          px: 0,
                          py: 1,
                          minWidth: "53px",
                        }}
                      >
                        <span
                          className="font EffraRegular"
                          style={{ fontSize: "0.75rem" }}
                        >
                          {pricing.duration} sec
                        </span>
                        <span
                          className="font EffraBold"
                          style={{ fontSize: "0.9rem" }}
                        >
                          {pricing.from}
                        </span>
                        <span
                          className="font EffraBold"
                          style={{ fontSize: "0.8rem" }}
                        >
                          {t("PAY")}
                        </span>
                      </Button>
                    </Box>
                  ))}
              </Box>
            </Box>
          )}
        </Box>
        {/* {!liveshow &&
          <MenuItem
            style={{ borderBottom: "1px solid rgb(52 52 52)" }}
            onClick={(e) => handleTip(e)}
          >
            <Icon>
              <img style={{ height: "100%" }} alt="tip" src={tipSvg} />
            </Icon>
            {t("Tip to")}{" "}
            {model && model.inHouseName ? model.inHouseName : model.firstName}
          </MenuItem>
        }

        <MenuItem
          style={{ borderBottom: "1px solid rgb(52 52 52)" }}
          onClick={(e) => handleMessage(e)}
        >
          <Icon>
            <img style={{ height: "100%" }} alt="message" src={messageSvg} />
          </Icon>
          {t("Private message")}
        </MenuItem>
        <MenuItem onClick={(e) => handleViewProfile(e)}>
          <Icon>
            <img style={{ height: "100%" }} alt="profile" src={profileSvg} />
          </Icon>
          {t("View profile")}
        </MenuItem> */}
      </Menu>
      <Dialog className="pop-up button" open={openD} onClose={handleCloseD}>
        <DialogTitle style={{ display: "flex", justifyContent: "center" }}>
          <Avatar
            className={
              "onlineCard " +
              styles.avatar +
              " " +
              (model && model.onlineStatus && styles.online)
            }
            alt={model && model.inHouseName + " profile picture"}
            src={model?.avatar?.imageMedium ?? fModel?.avatar?.imageMedium}
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText textAlign={"left"} sx={{ mb: 3 }}>
            <Typography
              color={"#77F73B"}
              style={{ font: "normal normal bold 20px/24px Effra" }}
            >
              {t("Send me tips")}
            </Typography>
            <Typography style={{ maxWidth: "250px" }}>
              {t("How much would you like to tip to me?")}
            </Typography>
          </DialogContentText>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <TextareaAutosize
              aria-label="Message"
              id="message"
              autoFocus
              multiline
              name="message"
              color="#ffffff"
              value={tipsAmount}
              onChange={handleChangeTips}
              style={{
                background: "#363636 0% 0% no-repeat padding-box",
                borderBottom: "1px solid grey",
                border: "unset",
                resize: "none",
                color: "white",
                borderRadius: "5px",
                opacity: 1,
                padding: "12px 18px",
                /*   height: '46px', */
                width: "117px",
                textAlign: "center",
                font: "normal normal normal 20px/24px Effra",
                /* color: '#6E6E6E' */
              }}
            />
            <Button
              onClick={handleSetMaxTips}
              style={{
                width: "35px",
                height: "20px",
                color: "#77F73B",
                font: "normal normal bold 16px/19px Effra",
              }}
            >
              {t("Max")}
            </Button>
          </div>
          {error && error !== "" && (
            <Grow in={appearD}>
              <Alert
                icon={false}
                variant="outlined"
                severity="error"
                style={{
                  border: "unset",
                  padding: "0",
                  margin: "0",
                  position: "absolute",
                  left: "0",
                  right: "0",
                  display: "block",
                }}
              >
                {error}
              </Alert>
            </Grow>
          )}
        </DialogContent>
        <DialogActions
          style={{ display: "flex", justifyContent: "center", padding: "0px" }}
        >
          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 4, mb: 2 }}
            color="success"
            style={{
              backgroundColor: "#77F73B",
              width: "103px",
              height: "38px",
              font: "normal normal bold 16px/19px Effra",
            }}
            onClick={handleSendTips}
          >
            {t("TIP")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default UserMenu;
