export const initialState = {
  session: [],
  //user: {},
  usersInChat: [],
  userInShow: {},
  usersOnline: [],
  onlines: [],
  //notification: {},
  highlight: [],
  notifications: [],
  model: {},
  model_earnings: {},
  memberJoinBonus: 50,
  models: [],
  camera: {},
  maintance: false,
  cameras: [],
  liveshow: {},
  liveshows: [],
  loadings: [],
  pricing: {},
  isPaused: null,
  connection: false,
  chat: [],
  error: {},
  medias: [],
  errors: [],
  message: {},
  messages: [],
  pools: [],
  myPools: [],
  post: [],
  posts: [],
  magic: {},
  magicTab: {},
  modal: {},
  tab: 'account',
  purchaseType: 'credits',
  profile: [],
  isInPrivateShow: { status: false, liveshowId: null, url: null, prevUrl: null },
  privateShowRequests: [],
  packages: [],
  //cryptos: [],
  socials: [],
  privateMessages: [],
  conversations: [],
  pinnedMessages: [],
  binom: {},
  nav: {},
  balance: [],
  events: [],
  imageVersion: Date.now(),
  edit: false,
  watching: [],
  socketConnectionId: null,
  app: {}
}